/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import kebabCase from "lodash/kebabCase"
import { Flex } from "@theme-ui/components"
import get from "lodash/get"

import { renderLineBreak } from "../helpers"

import Carousel from "../components/carousel"
import Column from "../components/column"
import Container from "../components/container"
import Quote from "../components/design-partner/quote"
import ModelSlider from "../components/model-slider"
import Row from "../components/row"
import Text from "../components/text"
import PageLink from "../components/page-link"
import ProjectCard from "../components/project-card"
import SEO from "../components/seo"

const DesignPartnerDetail = ({ data }) => {
  useEffect(() => {
    document.head.innerHTML += '<meta name="robots" content="noindex">'
  }, []);
  const renderDesignerPartnerInfoBladePattern = () => {
    let details = data.contentfulDesignPartner.details
      ? data.contentfulDesignPartner.details.details
        .split("\n")
        .map(line => line.split(":"))
        .map(entry => {
          return {
            title: entry[0],
            description: entry[1],
          }
        })
      : []
    return (
      <div
        sx={{
          py: theme => theme.spacing.vertical.md,
        }}
      >
        <Container>
          <Row>
            <Column size={[12, 2]}>
              <img
                src={data.contentfulDesignPartner.photo.file.url}
                alt=""
                sx={{
                  borderRadius: "50%",
                  width: 170,
                }}
              />
            </Column>
            <Column size={[12, 8]}>
              <Text type="h1" customStyle={{ fontSize: [40, null] }}>
                {renderLineBreak(data.contentfulDesignPartner.name)}
              </Text>
              {data.contentfulDesignPartner.website ? (
                <PageLink
                  link={data.contentfulDesignPartner.website}
                  customStyle={{
                    fontSize: 3,
                    fontFamily: "body",
                    fontWeight: "heading",
                    pt: 25,
                  }}
                  target="_self"
                >
                  {data.contentfulDesignPartner.website}
                </PageLink>
              ) : null}
              {details ? (
                <Row
                  customStyle={{
                    mt: theme => [theme.spacing.vertical.sm],
                  }}
                >
                  {details.map((detail, index) => (
                    <Column
                      size={[12, 12, 6, 6, 4, 4]}
                      key={index}
                      customStyle={{ mt: 3 }}
                    >
                      <div>
                        <Text>{detail.title}</Text>
                      </div>
                      <div>
                        <Text
                          customStyle={{
                            mt: theme => [0, 10],
                            fontSize: 3,
                            fontWeight: "bold",
                          }}
                        >
                          {detail.description}
                        </Text>
                      </div>
                    </Column>
                  ))}
                </Row>
              ) : null}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  const renderDesignPartnerFeaturedWork = () => {
    const fullWidthList = [true, true, true]

    if (!data.contentfulDesignPartner.featuredWork) return
    return (
      <Container fullWidth={fullWidthList}>
        <Row noGutters={fullWidthList}>
          <Column noGutters={fullWidthList}>
            <Carousel
              type="tertiary"
              customNavigationStyle={{
                height: [25, 25, 60],
                lineHeight: 0.5,
                width: [25, 25, 60],
              }}
            >
              {data.contentfulDesignPartner.featuredWork.map(
                (featuredWork, idx) => (
                  <div
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <img alt="" src={featuredWork.file.url} key={idx} />
                  </div>
                )
              )}
            </Carousel>
          </Column>
        </Row>
      </Container>
    )
  }
  const renderDesignPartnerBio = () => {
    if (!data.contentfulDesignPartner.bio) return
    return (
      <div>
        <Container>
          <Row>
            <Column
              customStyle={{
                alignItems: "center",
              }}
            >
              <div
                sx={{
                  maxWidth: 900,
                  columnCount: [1, 1, 2],
                  columnGap: 80,
                  py: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.lg,
                  ],
                }}
              >
                {data.contentfulDesignPartner.bio &&
                  documentToReactComponents(
                    data.contentfulDesignPartner.bio.json,
                    {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <Text
                            customStyle={{
                              mt: theme => theme.spacing.vertical.sm,
                              ":first-of-type": {
                                mt: 0,
                              },
                            }}
                          >
                            {children}
                          </Text>
                        ),
                      },
                    }
                  )}
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  const renderDesignerPartnerQuote = () => {
    if (!data.contentfulDesignPartner.quote) return

    return (
      <div
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          py: theme => theme.spacing.vertical.lg,
        }}
      >
        <Quote
          name={data.contentfulDesignPartner.name}
          quote={data.contentfulDesignPartner.quote}
          photo={data.contentfulDesignPartner.photo}
        />
      </div>
    )
  }

  const renderFeaturedProjects = () => {
    return (
      <div
        sx={{
          pt: theme => theme.spacing.vertical.lg,
        }}
      >
        <Container>
          <Row
            customStyle={{
              mb: theme => theme.spacing.vertical.lg,
            }}
          >
            <Text type="h2">
              {renderLineBreak(
                `Projects by\n${data.contentfulDesignPartner.name}`
              )}
            </Text>
          </Row>
          <Row>
            {data.contentfulDesignPartner.featuredProjects.map(project => (
              <Column
                size={[12, 12, 4]}
                customStyle={{
                  mb: theme => [theme.spacing.vertical.sm],
                  pl: 0,
                  pr: [0, 0, "20px"],
                  ":last-of-type": {
                    pr: 0,
                  },
                }}
                key={kebabCase(project.ctaDestination.ctaDestination)}
              >
                <ProjectCard
                  link={{
                    to: `/projects/${project.ctaDestination.ctaDestination}`,
                    type: "internal",
                  }}
                  href={`/projects/${project.ctaDestination.ctaDestination}`}
                  background={project.desktopImage.file.url}
                  text={[
                    project.title,
                  ]}
                />
              </Column>
            ))}
          </Row>
        </Container>
      </div>
    )
  }

  // const renderDesignerProjects = () => {
  //   if (!data.allContentfulProject?.edges?.length) return
  //   return (
  //     <div
  //       sx={{
  //         pt: theme => theme.spacing.vertical.lg,
  //       }}
  //     >
  //       <Container>
  //         <Row
  //           customStyle={{
  //             mb: theme => theme.spacing.vertical.lg,
  //           }}
  //         >
  //           <Text type="h2">
  //             {renderLineBreak(
  //               `Projects by\n${data.contentfulDesignPartner.name}`
  //             )}
  //           </Text>
  //         </Row>
  //         <Row>
  //           {data.allContentfulProject.edges.map(project => (
  //             <Column
  //               size={
  //                 data.allContentfulProject.edges.length === 3
  //                   ? [12, 12, 4]
  //                   : data.allContentfulProject.edges.length === 2
  //                     ? [12, 12, 6]
  //                     : [12, 12, 6]
  //               }
  //               customStyle={{
  //                 mb: theme => [theme.spacing.vertical.sm],
  //                 pl: 0,
  //                 pr: [0, 0, "20px"],
  //                 ":last-of-type": {
  //                   pr: 0,
  //                 },
  //               }}
  //               key={kebabCase(project.node.projectTitle.projectTitle)}
  //             >
  //               <ProjectCard
  //                 link={{
  //                   to: `/projects/${project.node.slug}`,
  //                   type: "internal",
  //                 }}
  //                 background={project.node.projectImages[0].file.url}
  //                 text={[
  //                   project.node.projectTitle.projectTitle,
  //                   project.node.projectLocation.projectLocation,
  //                 ]}
  //               />
  //             </Column>
  //           ))}
  //         </Row>
  //       </Container>
  //     </div>
  //   )
  // }
  const renderDesignerPartnerLivingHomes = () => {
    if (!data.allContentfulModelVariation.edges.length) return

    return (
      <div
        sx={{
          py: theme => theme.spacing.vertical.sm,
        }}
      >
        <Container>
          <Row
            customStyle={{
              mb: theme => theme.spacing.vertical.sm,
            }}
          >
            <Text type="h2">
              {renderLineBreak(
                `LivingHomes by\n${data.contentfulDesignPartner.name}`
              )}
            </Text>
          </Row>
          <Row>
            <Column>
              <ModelSlider
                data={data.allContentfulModelVariation}
                type="twoColumns"
              />
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  const renderBrowseByDesigner = () => {
    return (
      <div
        sx={{
          py: theme => theme.spacing.vertical.sm,
        }}
      >
        <Container>
          <Row
            customStyle={{
              mb: theme => theme.spacing.vertical.sm,
            }}
          >
            <Text type="h3">Other Design Collaborations</Text>
          </Row>
          <Row>
            {data.allContentfulDesignPartner.edges.map(designPartner => (
              <Column
                size={[12, 6, 4]}
                customStyle={{
                  mb: theme => theme.spacing.vertical.sm,
                }}
                key={kebabCase(designPartner.node.name)}
              >
                <PageLink
                  link={(designPartner.node ==="plant-design-studio") ? `/${designPartner.node}` :  `/architects/${designPartner.node.slug}`}
                  target="_self"
                >
                  <Flex
                    sx={{
                      alignItems: "center",
                      border: "1px solid #cccccc",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      sx={{
                        width: 90,
                        height: "auto",
                        mr: theme => theme.spacing.horizontal,
                      }}
                      src={designPartner.node.photo.file.url}
                      alt=""
                    />
                    <div>
                      <Text
                        customStyle={{
                          fontSize: 2,
                          fontWeight: "bold",
                        }}
                      >
                        {designPartner.node.name}
                      </Text>
                    </div>
                  </Flex>
                </PageLink>
              </Column>
            ))}
          </Row>
        </Container>
      </div>
    )
  }
  return (
    <React.Fragment>
      <SEO
        title={data.contentfulDesignPartner.name}
        description={get(data, "contentfulDesignPartner.metaDescription")}
        imageUrl={get(data, "contentfulDesignPartner.photo.file.url")}
      />
      <div>
        {renderDesignerPartnerInfoBladePattern()}
        {renderDesignPartnerFeaturedWork()}
        {renderDesignPartnerBio()}
        {renderDesignerPartnerQuote()}
        {!data.contentfulDesignPartner.hasOwnProperty("featuredProjects") ||
          data.contentfulDesignPartner.featuredProjects == null
          ? null
          : renderFeaturedProjects()}
        {renderDesignerPartnerLivingHomes()}
        {renderBrowseByDesigner()}
      </div>
    </React.Fragment>
  )
}

export default DesignPartnerDetail

export const query = graphql`
  query($slug: String!) {
    contentfulDesignPartner(slug: { eq: $slug }) {
      name
      metaDescription
      website
      bio {
        json
      }
      quote {
        quote {
          json
        }
      }
      photo {
        file {
          url
        }
      }
      shortBio {
        json
      }
      project {
        childContentfulProjectProjectTitleTextNode {
          projectTitle
        }
        projectImages {
          file {
            url
          }
        }
      }
      featuredWork {
        file {
          url
        }
      }
      details {
        details
      }
      featuredProjects {
        ...ProjectsBladePatternData
      }
    }

    allContentfulDesignPartner(filter: { slug: { ne: $slug } }) {
      edges {
        node {
          name
          slug
          photo {
            file {
              url
            }
          }
        }
      }
    }

    allContentfulModelVariation(
      sort: { fields: createdAt, order: DESC }
      filter: { model: { designPartner: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          baths
          beds
          id
          images {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          model {
            id
            slug
            designPartner {
              slug
            }
          }
          name
          squareFootage
        }
      }
    }
  }

  fragment ProjectsBladePatternData on ContentfulBasicCard {
    ctaDestination {
      ctaDestination
    }
    ctaDestinationExternal {
      ctaDestinationExternal
    }
    ctaTitle
    description {
      json
    }
    bios {
      json
    }
    title
    slug
    desktopImage {
      file {
        url
      }
    }
  }
`
